<template>
  <div class="box">
    <img src="@/img/logo-icon.png" class="logo" />
    <div class="name">微企乐</div>
    <div class="version">版本:{{ version }}</div>
    <van-button round type="primary" size="large" class="button" @click="down"
      >下载</van-button
    >
    <div class="shade" v-if="show">
      <img src="@/img/shade.png" alt="" />
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      url: "",
      version: "",
      show: false,
    };
  },
  created() {
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.show = true;
    }

    if (
      localStorage.getItem("wql_real_token") &&
      localStorage.getItem("wql_token") &&
      localStorage.getItem("wql_real_token") != "undefined"
    ) {
      this.$http.get(url.getApp).then((res) => {
        this.url = res.data.content[0].downloadUrl;
        this.version = res.data.content[0].version;
      });
    } else {
      localStorage.clear();
      this.$http
        .post(url.login, {
          grant_type: "client_credentials",
          appVersion: "1.0.0",
          appId: "web",
        })
        .then((res) => {
          localStorage.setItem("wql_token", res.access_token);
          this.$http.get(url.getApp).then((res) => {
            this.url = res.data.content[0].downloadUrl;
            this.version = res.data.content[0].version;
          });
        });
    }
  },
  methods: {
    down() {
      window.location.href = this.url;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: url("../../img/vip/no-bg.png") no-repeat;
  background-size: 100%;
  min-height: 100vh;
  text-align: center;

  .logo {
    width: 100px;
    height: 100px;
    padding-top: 80px;
  }
  .name {
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }
  .version {
    color: gray;
    font-size: 12px;
    padding-top: 10px;
  }
  .button {
    width: 200px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    margin-left: -100px;
  }
  .shade {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    img {
      width: 100%;
    }
  }
}
</style>
